import React from 'react';

export const Card = ({ children, className }) => (
  <div className={`bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md ${className}`}>
    {children}
  </div>
);

export const CardHeader = ({ children }) => (
  <div className="border-b pb-2 mb-2">
    {children}
  </div>
);

export const CardTitle = ({ children, className }) => (
  <h2 className={`text-xl font-bold ${className}`}>
    {children}
  </h2>
);

export const CardContent = ({ children, className }) => (
  <div className={`flex-grow ${className}`}>
    {children}
  </div>
);

export const CardDescription = ({ children, className }) => (
  <p className={`text-gray-600 dark:text-gray-300 ${className}`}>
    {children}
  </p>
);

export const CardFooter = ({ children }) => (
  <div className="mt-2">
    {children}
  </div>
);
