import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './components/Card';
import { Badge } from './components/Badge';
import { ExternalLink } from 'lucide-react';
import { motion } from 'framer-motion';

const projects = [
  {
    title: "Cencomer.com",
    description: "Plataforma integral de comercio electrónico que empodera a los vendedores para exhibir sus productos y conectar con clientes. Ofrece una interfaz intuitiva para gestionar catálogos y facilita la comunicación directa entre compradores y vendedores.",
    url: "https://cencomer.com",
    tags: ["E-commerce", "Catálogo de Productos", "Gestión de Contactos"],
    image: "/images/01.png"
  },
  {
    title: "QR Generator",
    description: "Herramienta versátil para la creación instantánea de códigos QR personalizados. Permite a los usuarios generar y descargar códigos QR para enlaces, texto o información de contacto, ideal para marketing digital y facilitar el acceso rápido a información.",
    url: "https://qr.cencomer.com",
    tags: ["Generador QR", "Marketing Digital", "Herramienta Web"],
    image: "/images/02.png"
  },
  {
    title: "Bot Services",
    description: "Portal informativo que explora el potencial de los chatbots en la atención al cliente y automatización de procesos. Detalla cómo nuestros servicios de bot pueden mejorar la eficiencia operativa y la experiencia del usuario en diversos sectores empresariales.",
    url: "https://bot.cencomer.com",
    tags: ["Chatbots", "Automatización", "Atención al Cliente"],
    image: "/images/03.png"
  },
  {
    title: "Centro Comercial Virtual",
    description: "Innovadora plataforma que revoluciona el concepto de tiendas online, ofreciendo soluciones de e-commerce auto-instalables. Permite a los emprendedores lanzar rápidamente su presencia en línea con tiendas personalizables y fáciles de gestionar desde cualquier dispositivo.",
    url: "https://centrocomercial.com.co",
    tags: ["E-commerce", "Tiendas Virtuales", "Soluciones Auto-instalables"],
    image: "/images/04.png"
  },
  {
    title: "Pico y Placa Medellín",
    description: "Aplicación web intuitiva que simplifica la consulta de las restricciones de circulación 'Pico y Placa' en Medellín. Ofrece información actualizada y fácil de entender, ayudando a los conductores a planificar sus desplazamientos y evitar sanciones.",
    url: "https://picoyplaca.cencomer.com",
    tags: ["Movilidad Urbana", "Consulta en Tiempo Real", "Planificación de Viajes"],
    image: "/images/05.png"
  }
];

const Portfolio = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900">
      <div className="container mx-auto px-4 py-16">
        <header className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4 text-gray-800 dark:text-white">Portafolio de Proyectos Luis Cabezas</h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">Desarrollador Web especializado en soluciones innovadoras</p>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card 
                className="w-full h-full flex flex-col overflow-hidden transition-shadow duration-300 hover:shadow-xl"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div className="relative overflow-hidden" style={{ paddingBottom: "62.5%" }}>
                  <img 
                    src={project.image} 
                    alt={project.title}
                    className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 transform hover:scale-110"
                  />
                </div>
                <CardHeader>
                  <CardTitle className="text-xl font-semibold text-gray-800 dark:text-white">{project.title}</CardTitle>
                </CardHeader>
                <CardContent className="flex-grow">
                  <CardDescription className="text-gray-600 dark:text-gray-300 mb-4">{project.description}</CardDescription>
                  <div className="flex flex-wrap gap-2">
                    {project.tags.map((tag, tagIndex) => (
                      <Badge key={tagIndex} variant="secondary" className="bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100">
                        {tag}
                      </Badge>
                    ))}
                  </div>
                </CardContent>
                <CardFooter>
                  <a 
                    href={project.url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                  >
                    Explorar Proyecto <ExternalLink className="ml-2" size={16} />
                  </a>
                </CardFooter>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
      <footer className="bg-gray-800 text-white p-4 text-center text-sm">
        <p>&copy; {new Date().getFullYear()} Cencomer.com Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default Portfolio;
