import React from 'react';

export const Badge = ({ children, variant = 'primary', className }) => {
  const baseStyle = 'inline-flex items-center px-2 py-1 text-xs font-semibold rounded-full';
  const variantStyle = variant === 'primary'
    ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100'
    : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-100';

  return (
    <span className={`${baseStyle} ${variantStyle} ${className}`}>
      {children}
    </span>
  );
};
